.media-select-button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.media-select-button-container .top {
  display: flex;
  margin: 0rem 0rem 1rem 0rem;
}
.media-select-button-container .top .media-count {
  margin: 0rem 0rem 0rem 0.3rem;
  padding: 0.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.preview {
  display: flex;
  border: 1px dotted rgba(128, 128, 128, 0.337);
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.editor {
  width: 90%;
  padding: 0.5rem;
}

.label {
  width: 100%;
  text-align: left;
}

.field {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.field .plusButton {
  cursor: pointer;
}/*# sourceMappingURL=elements.module.css.map */