@import '~antd/dist/antd.css';
@import "./styles/Global.css";

.App {
  display: block;
  text-align: center;
}

.SiteMode {
  display: none;
}

/* @media screen and (max-width:1000px) {
  .App {
    display: none !important;
  }

  .SiteMode {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column;
    height: 100vh !important;
  }

  .SiteMode .image {
    width: 85%;
    height: 300px;
  }

  .SiteMode .image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .SiteMode h1 {
    color: black;
    width: 85%;
    text-align: center;
  }

  .SiteMode small {
    font-size: 18px;
    text-align: center;
  }
} */