body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 10px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background: rgb(209, 209, 209);
  /* border-radius: 100vw; */
  /* margin-block: .5rem; */
}

::-webkit-scrollbar-thumb {
  background: #d8c83d;
  /* border-radius: 100vw; */
  /* border: 5px solid red; */
}

::-webkit-scrollbar-thumb:hover {
  background: #DDCC30;
}