.image-card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    padding: .5rem;
    cursor: pointer;
    transition: all .5s ease;
}

.selected {
    box-shadow: none;
}

.tick-icon {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(12, 228, 138, 0.566);
    font-size: 2rem;
    transition: all 1s ease;
}

.caption {
    font-size: .6rem;
    padding: .2rem;
    width: 100%;
    line-height: 100%;
    text-align: center;
    border: 1px dotted rgba(255, 0, 0, 0.332);
}