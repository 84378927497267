.header {
    padding: .5rem 1rem .5rem 1rem;
    height: 5%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    border-radius: .5rem;
    margin-bottom: 1rem;
}

.header h4 {
    font-size: 2rem;
}

.header h6 {
    font-size: .9rem;
}

.footer {
    width: 100%;
    /* height: fit-content; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 1rem;
    height: 10%;
}

.list {
    /* height: 85%; */
    overflow-y: auto;
    padding: 1rem;
}

.modal-select-dialogbox {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 1rem;
    font-size: 2rem;
    color: red;
    z-index: 1000;
    background: white;
    padding: 1rem;
    min-height: 2rem;
    min-width: 75rem;
    max-height: 40rem;
    max-width: 60rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}