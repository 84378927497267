.green-button {
	background-color: #0034f0;
	border-radius: 0.5rem;
	border: none;
	padding: 0.5rem 1.5rem 0.5rem 1.5rem;
	font-size: 1rem;
	color: white;
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
		rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
	margin-right: 0.5rem;
	cursor: pointer;
	width:fit-content;
	width:5rem;
}

.red-button {
	background-color: #f01400;
	border-radius: 0.5rem;
	border: none;
	padding: 0.5rem 1.5rem 0.5rem 1.5rem;
	font-size: 1rem;
	color: white;
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
		rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
	margin-right: 0.5rem;
	cursor: pointer;
}
.upload-button {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	padding: 0.5rem 1rem 0.5rem 1rem;
	background: white;
	border: 1px solid gray;
	font-size: 1rem;
	color: black;
	cursor: pointer;

	.text {
		text-align: center;
		margin: 0rem 0.5rem 0rem 0rem;
	}
}

.dark-blue-button {
	background: #353356;
	padding: 0.3rem 1rem 0.3rem 1rem;
	color: white;
	border-radius: 13px;
	border: none;
	font-family: "Jost";
	font-style: normal;
	font-weight: 400;
	font-size: 28px;
	display: flex;
	align-items: center;
	text-align: center;
	font-variant: small-caps;
	color: #d9d9d9;
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
	cursor: pointer;
	letter-spacing: 1px;
	transition: background 1s ease;
	min-width: 7rem;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;

	&:hover {
		background-color: #ddcc30;
		color: #353356;
	}
}

.read-more-button {
	background: #ddcc30;
	padding: 0.5rem 1rem 0.5rem 1rem;
	color: black;
	border-radius: 13px;
	border: none;
	font-family: "Jost";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	text-align: center;
	font-variant: small-caps;
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
	cursor: pointer;
	letter-spacing: 1px;
	transition: background 0.5s ease;
}
